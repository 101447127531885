import React, { useEffect } from 'react';
import { useState } from 'react';
import './Menu.css';
import lang from '../lang.json';
import Language from './Language';
import { useLanguage } from '../utills/LanguageContext';

function Menu(props) {
    const { changeLanguage, language } = useLanguage();
    const [menuOpen, setMenuOpen] = useState(false);

    function selectLanguage(lang) {
        changeLanguage(lang);
        setMenuOpen(false);

        let url = window.location.href;

        if (url.includes('/pt') || url.includes('/en') || url.includes('/es')) {
            url = url.replace(/\/pt|\/en|\/es/, `/${lang}`);
            window.location.href = url;
        }

    }

    useEffect(() => {
        console.log('props.language', props.language);
    }, [props.language]);

    return (
        <div className='menu relative'>
            <div className="menuSelect" onClick={e => setMenuOpen(!menuOpen)}>
                <p className='selectedLanguageText'>{language}</p>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow-drop-down">
                        <path id="Vector" d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="#394A64" />
                    </g>
                </svg>
            </div>

            {menuOpen && (
                <div className="menuOpen absolute top-[30px]">
                    <div className="menuOpenWrapper">
                        {lang.map((item, index) => (
                            <Language
                                langCode={item.langCode}
                                langName={item.name}
                                countryCode={item.countryCode}
                                key={index}
                                selectedLanguage={props.language}
                                selectLanguage={selectLanguage}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Menu;
