
import React, { useEffect, useState } from 'react'
import Faq from '../Components/Faq'
import Create from './Main/Create'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Sleazy/slide1.gif'
import slide2 from '../Images/Paid/Sleazy/slide2.gif'
import slide3 from '../Images/Paid/Sleazy/slide3.gif'
import slide4 from '../Images/Paid/Sleazy/slide4.gif'
import slideBg1 from '../Images/bg/Sleazy/bg1.png'
import slideBg2 from '../Images/bg/Sleazy/bg3.png'
import slideBg3 from '../Images/bg/Sleazy/bg2.png'
import slideBg4 from '../Images/bg/Sleazy/bg4.png'
import slideBgDesk1 from '../Images/bg/Sleazy/bg4Desk.png'
import slideBgDesk2 from '../Images/bg/Sleazy/bg2Desk.png'
import slideBgDesk3 from '../Images/bg/Sleazy/bg3Desk.png'
import slideBgDesk4 from '../Images/bg/Sleazy/bg1Desk.png'
import { useLanguage } from '../utills/LanguageContext'
import sliderBg1 from '../Images/slideBg/sleazy1.png'
import sliderBg1Desk from '../Images/slideBg/sleazy1Desk.png'
import { Helmet } from 'react-helmet'

function Sleazy() {
    
    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.sleazyCardName1, subtitle: languageData?.sleazyCardRole1, workText: languageData?.sleazyCardBold1, workText2: languageData?.sleazyCardBold1_db, paidText: languageData?.sleazyCardLight1, underSlideTitle: languageData?.sleazyCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.sleazyCardName2, subtitle: languageData?.sleazyCardRole2, workText: languageData?.sleazyCardBold2, paidText: languageData?.sleazyCardLight2, underSlideTitle: languageData?.sleazyCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.sleazyCardName3, subtitle: languageData?.sleazyCardRole3, workText: languageData?.sleazyCardBold3, paidText: languageData?.sleazyCardLight3, underSlideTitle: languageData?.sleazyCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.sleazyCardName4, subtitle: languageData?.sleazyCardRole4, workText: languageData?.sleazyCardBold4, paidText: languageData?.sleazyCardLight4, underSlideTitle: languageData?.sleazyCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageData?.sleazyCardName1, subtitle: languageData?.sleazyCardRole1, workText: languageData?.sleazyCardBold1, workText2: languageData?.sleazyCardBold1_db, paidText: languageData?.sleazyCardLight1, underSlideTitle: languageData?.sleazyCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.sleazyCardName2, subtitle: languageData?.sleazyCardRole2, workText: languageData?.sleazyCardBold2, paidText: languageData?.sleazyCardLight2, underSlideTitle: languageData?.sleazyCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.sleazyCardName3, subtitle: languageData?.sleazyCardRole3, workText: languageData?.sleazyCardBold3, paidText: languageData?.sleazyCardLight3, underSlideTitle: languageData?.sleazyCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.sleazyCardName4, subtitle: languageData?.sleazyCardRole4, workText: languageData?.sleazyCardBold4, paidText: languageData?.sleazyCardLight4, underSlideTitle: languageData?.sleazyCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

useEffect(() => {
    const lang = window.location.pathname.split('/')[1];
    changeLanguage(lang);
}, [])
    
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Sleazy - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.sleazyQuoteDesc} quoteName={languageData?.sleazyQuoteName} quoterole={languageData?.sleazyQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Sleazy
