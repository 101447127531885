import React, { useEffect, useState } from 'react';
import logo from '../Images/header_logo.png';
import logoHost from '../Images/hostPage/logo_host.png';
import menu from '../Images/menuIcon.svg';
import BurgerMenu from './BurgerMenu';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../utills/LanguageContext';
import axios from 'axios';
import Menu from '../Menu/Menu';

function Header({ hostData, language }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { languageData } = useLanguage();

  const location = useLocation();
  const slug = location.pathname.split("/").pop();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [isHostPage, setIsHostPage] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [profile, setProfile] = useState({});

  const getProfile = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/hosts/${slug}/profile`);
      setIsHostPage(true);
      setFirstName(res.data.firstName);
      setProfile(res.data);
      return res.data;
    } catch (error) {
      setIsHostPage(false);
    }
  }

  useEffect(() => {
    if (slug === undefined) return getProfile();
  }, [slug]);

  return (
    <div>
      <BurgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`w-full  fixed z-50 top-0 h-[50px] bg-[#0D0D0D] flex justify-around items-center`}>
        <div className={`max-w-[1100px] w-full flex ${isHostPage ? 'justify-between' : 'justify-around'}  items-center`}>
          {isHostPage ?
            ''
            :
            <div className='w-[33%]'>
              <img
                className='w-[24px] ml-4 cursor-pointer'
                src={menu}
                alt="menuIcon"
                onClick={handleMenuClick}
              />
            </div>
          }
          <div className={`${isHostPage ? 'w-[70%]' : 'w-[33%]'}`}>
            {isHostPage ? (
              <div onClick={handleLogoClick} className='py-4 flex justify-start ml-2 items-center cursor-pointer'>
                <img className='w-[100px]' src={logoHost} alt="logo" />
                <p className='text-white playwrite md:text-[24px] ml-2'>{firstName}</p>
              </div>
            ) : (
              <Link to={`/${language}`} >
                <img
                  className='w-[64px] m-auto'
                  src={logo}
                  alt="logo"
                />
              </Link>
            )}
          </div>
          <div className={`${isHostPage ? 'w-[50%]' : 'w-[33%]'} flex justify-end pr-3 py-1`}>
            {isHostPage ?
              <Link target='_blank' to={profile.sections.en[0].section1.section1_cta_link}>
                <button className='bg-[#FDBA0B] px-1.5 py-1.5 md:py-1 md:px-3 text-[#0D0D0D] inter font-semibold text-xs md:text-sm rounded-[100px] md:tracking-[1px] ml-2'>{languageData.signUpBtn}</button>
              </Link>
              : <Menu language={language} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
