import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import { Link, useLocation } from "react-router-dom";
import sliderBg1 from "../../Images/slideBg1.png";
import sliderBg1Desk from "../../Images/slideBg1Desk.png";
import sliderBg2 from "../../Images/slideBg2.png";
import sliderBg2Desk from "../../Images/slideBg2Desk.png";
import sliderBg3 from "../../Images/slideBg3.png";
import sliderBg3Desk from "../../Images/slideBg3Desk.png";
import sliderBg4 from "../../Images/slideBg4.png";
import sliderBg4Desk from "../../Images/slideBg4Desk.png";
import sliderBg5 from "../../Images/slideBg5.png";
import sliderBg5Desk from "../../Images/slideBg5Desk.png";
import sliderBg6 from "../../Images/slideBg6.png";
import sliderBg6Desk from "../../Images/slideBg6Desk.png";
import sliderBg8 from "../../Images/slideBg8.png";
import sliderBg8Desk from "../../Images/slideBg8Desk.png";
import sliderBg9 from "../../Images/slideBg9.png";
import sliderBg9Desk from "../../Images/slideBg9Desk.png";
import {useLanguage} from '../../utills/LanguageContext';

function SliderBg({scrollToHubspot}) {
  const { languageData } = useLanguage();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const swiperRef = useRef(null);
 const location = useLocation();

    
  const sliderBgNew = [
    {
      imageUrl: sliderBg1,
      imageUrlDesk: sliderBg1Desk,
      title1: languageData?.mainQuote1,
      title2: languageData?.mainQuote2,
      name: languageData?.mainQuoteName,
      role: languageData?.mainQuoteRole,
    },
    {
      imageUrl: sliderBg2,
      imageUrlDesk: sliderBg2Desk,
      title2: languageData?.athleteQuoteDesc,
      name: languageData?.athleteQuoteName,
      role: languageData?.athleteQuoteRole,
    },
    {
      imageUrl: sliderBg3,
      imageUrlDesk: sliderBg3Desk,
      title2: languageData?.modelsQuoteDesc,
      name: languageData?.modelsQuoteName,
      role: languageData?.modelsQuoteRole,
    },
    {
      imageUrl: sliderBg4,
      imageUrlDesk: sliderBg4Desk,
      title2: languageData?.artistsQuoteDesc,
      name: languageData?.artistsQuoteName,
      role: languageData?.artistsQuoteRole,
    },
    {
      imageUrl: sliderBg5,
      imageUrlDesk: sliderBg5Desk,
      title2: languageData?.teachersQuoteDesc,
      name: languageData?.teachersQuoteName,
      role: languageData?.teachersQuoteRole,
    },
    {
      imageUrl: sliderBg6,
      imageUrlDesk: sliderBg6Desk,
      title2: languageData?.entrepreneursQuoteDesc,
      name: languageData?.entrepreneursQuoteName,
      role: languageData?.entrepreneursQuoteRole,
    },
    {
      imageUrl: sliderBg1,
      imageUrlDesk: sliderBg1Desk,
      title1: '',
      title2: languageData?.QuoteDesc_db1,
      name: languageData?.mainQuoteName,
      role: languageData?.mainQuoteRole,
    },
    {
      imageUrl: sliderBg1,
      imageUrlDesk: sliderBg1Desk,
      title1: '',
      title2: languageData?.QuoteDesc_db2,
      name: languageData?.mainQuoteName,
      role: languageData?.mainQuoteRole,
    },
    {
      imageUrl: sliderBg8,
      imageUrlDesk: sliderBg8Desk,
      title1: '',
      title2: languageData?.sleazyQuoteDesc,
      name: languageData?.sleazyQuoteName,
      role: languageData?.sleazyQuoteRole,
    },
    {
      imageUrl: sliderBg9,
      imageUrlDesk: sliderBg9Desk,
      title1: '',
      title2: languageData?.agentsQuoteDesc,
      name: languageData?.agentsQuoteName,
      role: languageData?.agentsQuoteRole,
    },
  ];

  useEffect(() => {
    const segments = location.pathname.split("/");
    const page = segments[segments.length - 1];

    const pageSlideMapping = {
      athlete: 1,
      artists: 2,
      teachers: 3,
      models: 4,
      entrepreneurs: 6,
      influencer: 5,
      licensed: 7,
      sassy: 8,
      "agents-fashion": 9,
    };

    const slideIndex = pageSlideMapping[page] || 0;
    setActiveSlideIndex(slideIndex);

    if (swiperRef.current) {
      swiperRef.current.slideTo(slideIndex);
    }
  }, [location.pathname]);

  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.realIndex);
  };
  const isAgentsFashion = location.pathname.includes("/agents-fashion");
  return (
    <div className="w-full max-w-lg md:max-w-[unset] mx-auto relative">
      <Swiper
        className="mySwiper relative"
        modules={[Navigation, Autoplay, Pagination]}
        allowTouchMove={false}
        onSlideChange={handleSlideChange}
        initialSlide={activeSlideIndex}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {sliderBgNew.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              src={slide.imageUrl}
              alt={`Slide ${index + 1}`}
              className="w-full object-cover md:h-[578px] md:hidden"
            />
            <img
              src={slide.imageUrlDesk}
              alt={`Slide ${index + 1}`}
              className="w-full object-cover md:h-[578px] hidden md:block"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="md:max-w-[500px] w-full md:mx-auto absolute z-10 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <div className="max-w-[375px] w-full md:m-auto px-2">
          <p className="text-white text-2xl text-right font-normal lato tracking-[2px]">
            {sliderBgNew[activeSlideIndex]?.title1}
          </p>
          <p className="text-white text-2xl text-right font-normal lato tracking-[2px]">
          “{sliderBgNew[activeSlideIndex]?.title2}”
          </p>
          <p className="text-white text-lg font-semibold text-right mt-6 leading-6">
            {sliderBgNew[activeSlideIndex]?.name}
          </p>
          <p className="text-white text-base font-light text-right">
            {sliderBgNew[activeSlideIndex]?.role}
          </p>
        </div>
      </div>

      <Link
        className="w-full absolute z-10 bottom-4"
        to="https://app.morgis.com/auth/signup-host"
        target="_blank"
        onClick={(e) => {
          if (isAgentsFashion === true) {
            e.preventDefault();
            scrollToHubspot();
          }
        }}
      >
        <button  className="bg-[#8649D1] w-full max-w-[250px] md:max-w-[450px] md:h-[70px] m-auto text-white text-lg h-[50px] flex items-center justify-center rounded-[21px] font-medium tracking-[1px]">
          {languageData?.mainHomeBtn}
        </button>
      </Link>
    </div>
  );
}

export default SliderBg;
