import React, { useState, useRef } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Artists/slide1.gif'
import slide2 from '../Images/Paid/Artists/slide2.gif'
import slide3 from '../Images/Paid/Artists/slide3.gif'
import slide4 from '../Images/Paid/Artists/slide4.gif'
import slideBg1 from '../Images/bg/Agents/bg1.png'
import slideBg2 from '../Images/bg/Agents/bg2.png'
import slideBg3 from '../Images/bg/Agents/bg3.png'
import slideBg4 from '../Images/bg/Agents/bg4.png'
import slideBgDesk1 from '../Images/bg/Agents/bg1Desk.png'
import slideBgDesk2 from '../Images/bg/Agents/bg2Desk.png'
import slideBgDesk3 from '../Images/bg/Agents/bg3Desk.png'
import slideBgDesk4 from '../Images/bg/Agents/bg4Desk.png'
import { useLanguage } from '../utills/LanguageContext'
import sliderBg1 from '../Images/slideBg/artists1.png'
import sliderBg1Desk from '../Images/slideBg/artists1Desk.png'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import HubspotForm from 'react-hubspot-form'

function Agents() {

    const { language } = useLanguage();

    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.agentsCardName1, subtitle: languageData?.agentsCardRole1, workText: languageData?.agentsCardBold1, workText2: languageData?.agentsCardBold1_db, paidText: languageData?.agentsCardLight1, underSlideTitle: languageData?.agentsCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.agentsCardName2, subtitle: languageData?.agentsCardRole2, workText: languageData?.agentsCardBold2, workText2: languageData?.agentsCardBold2_db, paidText: languageData?.agentsCardLight2, underSlideTitle: languageData?.agentsCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.agentsCardName3, subtitle: languageData?.agentsCardRole3, workText: languageData?.agentsCardBold3, workText2: languageData?.agentsCardBold3_db, paidText: languageData?.agentsCardLight3, underSlideTitle: languageData?.agentsCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.agentsCardName4, subtitle: languageData?.agentsCardRole4, workText: languageData?.agentsCardBold4, workText2: languageData?.agentsCardBold4_db, paidText: languageData?.agentsCardLight4, underSlideTitle: languageData?.agentsCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageData?.agentsCardName1, subtitle: languageData?.agentsCardRole1, workText: languageData?.agentsCardBold1, workText2: languageData?.agentsCardBold1_db, paidText: languageData?.agentsCardLight1, underSlideTitle: languageData?.agentsCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.agentsCardName2, subtitle: languageData?.agentsCardRole2, workText: languageData?.agentsCardBold2, workText2: languageData?.agentsCardBold2_db, paidText: languageData?.agentsCardLight2, underSlideTitle: languageData?.agentsCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.agentsCardName3, subtitle: languageData?.agentsCardRole3, workText: languageData?.agentsCardBold3, workText2: languageData?.agentsCardBold3_db, paidText: languageData?.agentsCardLight3, underSlideTitle: languageData?.agentsCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.agentsCardName4, subtitle: languageData?.agentsCardRole4, workText: languageData?.agentsCardBold4, workText2: languageData?.agentsCardBold4_db, paidText: languageData?.agentsCardLight4, underSlideTitle: languageData?.agentsCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];


    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])

    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];

    const getFormData = () => {
        switch (language) {
            case 'es': return {
                portalId: "25642757",
                formId: "546a6470-bde0-4a01-8d4f-146b901c1fc4"
            }
            case 'en':
                return {
                    portalId: "25642757",
                    formId: "8a505a4a-518b-4663-8099-2fb4ef70428f"
                }
            case 'pt':
                return {
                    portalId: "25642757",
                    formId: "5d3b69f2-5d03-4929-83f6-8b1a286434fc"
                }
            default:
                return {
                    portalId: "25642757",
                    formId: "8a505a4a-518b-4663-8099-2fb4ef70428f"
                }
        }
    };
    const hubspotRef = useRef(null);

    const scrollToHubspot = () => {
        hubspotRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div>
            <Helmet>
                <title>Agents - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home scrollToHubspot={scrollToHubspot} slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create scrollToHubspot={scrollToHubspot} />
                <Host scrollToHubspot={scrollToHubspot} />
                <AdvantagesList scrollToHubspot={scrollToHubspot} />
                <SliderBg scrollToHubspot={scrollToHubspot} sliderBg={sliderBg} quoteTitle={languageData?.artistsQuoteDesc} quoteName={languageData?.artistsQuoteName} quoterole={languageData?.artistsQuoteRole} />
                <Ingredient scrollToHubspot={scrollToHubspot} />
                
                <div className='max-w-[1120px] m-auto w-[90%]'ref={hubspotRef}>
                    <div className='py-8 mt-6'>
                    <HubspotForm
                        portalId={getFormData().portalId}
                        formId={getFormData().formId}
                        onSubmit={() => alert('Submit!')}
                        loading={<div>Loading...</div>}
                    />
                    </div>
                </div>
                <Faq />
            </div>
        </div>
    )
}

export default Agents
