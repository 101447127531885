import React, { useState, useEffect } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/slide1.gif';
import slide2 from '../Images/Paid/slide2.gif';
import slide3 from '../Images/Paid/slide3.gif';
import slide4 from '../Images/Paid/slide4.gif';
import slideBgDesk1 from '../Images/homeBg1Desk.png';
import slideBgDesk2 from '../Images/homeBg2Desk.png';
import slideBgDesk3 from '../Images/homeBg3Desk.png';
import slideBgDesk4 from '../Images/homeBg4Desk.png';
import slideBg1 from '../Images/homeBg1.png';
import slideBg2 from '../Images/homeBg2.png';
import slideBg3 from '../Images/homeBg3.png';
import slideBg4 from '../Images/homeBg4.png';
import { useLanguage } from '../utills/LanguageContext';
import sliderBg1 from '../Images/slideBg1.png';
import sliderBg1Desk from '../Images/slideBg1Desk.png';

function Main() {
    
    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.homeCardName1, subtitle: languageData?.homeCardRole1, workText: languageData?.homeCardBold1, paidText: languageData?.homeCardLight1, underSlideTitle: languageData?.homeCardSpan1, imageBgDesk: slideBgDesk1, imageBg: slideBg1 },
        { id: 1, img: slide2, title: languageData?.homeCardName2, subtitle: languageData?.homeCardRole2, workText: languageData?.homeCardBold2, paidText: languageData?.homeCardLight2, underSlideTitle: languageData?.homeCardSpan2, imageBgDesk: slideBgDesk2, imageBg: slideBg2 },
        { id: 2, img: slide3, title: languageData?.homeCardName3, subtitle: languageData?.homeCardRole3, workText: languageData?.homeCardBold3, paidText: languageData?.homeCardLight3, underSlideTitle: languageData?.homeCardSpan3, imageBgDesk: slideBgDesk3, imageBg: slideBg3 },
        { id: 3, img: slide4, title: languageData?.homeCardName4, subtitle: languageData?.homeCardRole4, workText: languageData?.homeCardBold4, paidText: languageData?.homeCardLight4, underSlideTitle: languageData?.homeCardSpan4, imageBgDesk: slideBgDesk4, imageBg: slideBg4 },
        { id: 0, img: slide1, title: languageData?.homeCardName1, subtitle: languageData?.homeCardRole1, workText: languageData?.homeCardBold1, paidText: languageData?.homeCardLight1, underSlideTitle: languageData?.homeCardSpan1, imageBgDesk: slideBgDesk1, imageBg: slideBg1 },
        { id: 1, img: slide2, title: languageData?.homeCardName2, subtitle: languageData?.homeCardRole2, workText: languageData?.homeCardBold2, paidText: languageData?.homeCardLight2, underSlideTitle: languageData?.homeCardSpan2, imageBgDesk: slideBgDesk2, imageBg: slideBg2 },
        { id: 2, img: slide3, title: languageData?.homeCardName3, subtitle: languageData?.homeCardRole3, workText: languageData?.homeCardBold3, paidText: languageData?.homeCardLight3, underSlideTitle: languageData?.homeCardSpan3, imageBgDesk: slideBgDesk3, imageBg: slideBg3 },
        { id: 3, img: slide4, title: languageData?.homeCardName4, subtitle: languageData?.homeCardRole4, workText: languageData?.homeCardBold4, paidText: languageData?.homeCardLight4, underSlideTitle: languageData?.homeCardSpan4, imageBgDesk: slideBgDesk4, imageBg: slideBg4 }
    ];

    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])

    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        },
        
    ];

    return (
        <div>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.mainQuote2} quoteName={languageData?.mainQuoteName} quoterole={languageData?.mainQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    );
}

export default Main;
