import React, { useState, useEffect, useRef } from "react";
import { useLanguage } from "../../utills/LanguageContext";
import { Link } from "react-router-dom";
import { cn } from "../../utills/utills";
import { useLocation } from "react-router-dom";

function Create({scrollToHubspot}) {
  const { languageData } = useLanguage();
  const [slideStep, setSlideStep] = useState(0);
  const containerRef = useRef(null);
  const location = useLocation();
  const isAgentsFashion = location.pathname.includes("/agents-fashion");
  
  useEffect(() => {
    const container = containerRef.current;
    const observerOptions = {
      root: null, // Using the viewport as the root
      rootMargin: "0px",
      threshold: [1, 0.5, 1], // Array of thresholds to trigger callback
    };

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Calculate the progress based on the intersection ratio
          const progress = Math.round(entry.intersectionRatio * 4);
          if (progress !== slideStep) {
            setSlideStep(3);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);

    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, [slideStep]);

  return (
    <div ref={containerRef} className="createGradient bg-cover">
      <div
        className={cn(
          "bg-createBg2 md:bg-createBg2Desk bg-cover min-h-[420px] duration-300 bg-center"
        )}
      >
        <div
          className={cn("h-full w-full bg-black/60 duration-500 opacity-0", {
            "opacity-100": slideStep !== 0,
          })}
        ></div>
      </div>
      {/*
            <p className="text-center text-[#FEB80C] text-[58px] font-medium uppercase tracking-[10px] leading-[60px] md:leading-[50px] mt-[-85px] md:mt-[-55px] absolute left-1/2 transform -translate-x-1/2">
                <span
                    className={cn(
                        'top-[200px] relative opacity-0 duration-500',
                        { 'opacity-100 top-0': slideStep >= 1 },
                        { 'opacity-100 top-[-20px]': slideStep === 2 },
                        { 'opacity-100 top-[-40px]': slideStep === 3 }
                    )}
                >
                    {languageData?.mainCreateTitle1}
                    <br />
                </span>
                <span
                    className={cn(
                        'top-[200px] relative opacity-0 duration-500',
                        { 'opacity-100 top-0': slideStep >= 2 },
                        { 'opacity-100 top-[-20px]': slideStep === 3 }
                    )}
                >
                    {languageData?.mainCreateTitle2}
                    <br />
                </span>
                <span
                    className={cn(
                        'top-[200px] relative opacity-0 duration-500',
                        { 'opacity-100 top-0': slideStep >= 3 }
                    )}
                >
                    {languageData?.mainCreateTitle3}
                    <br />
                </span>
            </p>
*/}

      <div className="max-w-[430px] w-full mt-[-162px] md:mt-[-162px] absolute left-1/2 transform -translate-x-1/2">
        <p className="text-[58px] md:text-[72px] text-center uppercase font-medium text-[#FEB80C] tracking-[10px] md:tracking-[12px]">
          {isAgentsFashion
            ? languageData?.mainCreateTitle1Agents
            : languageData?.mainCreateTitle1}
        </p>
        <p className="text-[58px] md:text-[72px] text-center uppercase font-medium text-[#FEB80C] leading-8 md:leading-[unset] tracking-[10px] md:tracking-[12px]">
          {isAgentsFashion
            ? languageData?.mainCreateTitle2Agents
            : languageData?.mainCreateTitle2}
        </p>
        <p className="text-[58px] md:text-[72px] text-center uppercase font-medium text-[#FEB80C] tracking-[10px] md:tracking-[12px]">
          {isAgentsFashion
            ? languageData?.mainCreateTitle3Agents
            : languageData?.mainCreateTitle3}
        </p>
      </div>
      <div className="pt-14 md:pt-40 pb-6 p-3">
        <div
          className={cn("opacity-0 duration-500 text-center", {
            "opacity-100": slideStep === 3,
          })}
        >
          <p className="text-xl md:text-2xl font-medium leading-8 md:max-w-[540px] text-white text-center md:m-auto md:pb-6 md:pt-12">
            {isAgentsFashion
              ? languageData?.mainCreateSubTitleAgents?.split("\n")
                  .map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
              : languageData?.mainCreateSubTitle}
          </p>
          <Link
            className="w-full"
            to="https://app.morgis.com/auth/signup-host"
            target="_blank"
            onClick={(e) => {
              if (isAgentsFashion === true) {
                e.preventDefault();
                scrollToHubspot();
              }
            }}
          >
            <button className="bg-white m-auto flex items-center justify-center rounded-[21px] my-2 mt-8 py-4 px-4 tracking-[1px] max-w-[250px] md:max-w-[450px] w-full">
              <p className="text-[#8B6DE8] text-lg md:text-2xl font-medium">
                {languageData?.createNewBtn}
              </p>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Create;
