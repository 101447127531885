
import React, { useState } from 'react'
import Faq from '../Components/Faq'
import Create from './Main/Create'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Models/slide1.gif'
import slide2 from '../Images/Paid/Models/slide2.gif'
import slide3 from '../Images/Paid/Models/slide3.gif'
import slide4 from '../Images/Paid/Models/slide4.gif'
import slideBg1 from '../Images/bg/Models/bg1.png'
import slideBg2 from '../Images/bg/Models/bg2.png'
import slideBg3 from '../Images/bg/Models/bg3.png'
import slideBg4 from '../Images/bg/Models/bg4.png'
import slideBgDesk1 from '../Images/bg/Models/bg1Desk.png'
import slideBgDesk2 from '../Images/bg/Models/bg2Desk.png'
import slideBgDesk3 from '../Images/bg/Models/bg3Desk.png'
import slideBgDesk4 from '../Images/bg/Models/bg4Desk.png'
import { useLanguage } from '../utills/LanguageContext'
import sliderBg1 from '../Images/slideBg/models1.png'
import sliderBg1Desk from '../Images/slideBg/models1Desk.png'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

function Models() {
    
    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.modelsCardName2, subtitle: languageData?.modelsCardRole2, workText: languageData?.modelsCardBold2, paidText: languageData?.modelsCardLight2, underSlideTitle: languageData?.modelsCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.modelsCardName3, subtitle: languageData?.modelsCardRole3, workText: languageData?.modelsCardBold3, paidText: languageData?.modelsCardLight3, underSlideTitle: languageData?.modelsCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.modelsCardName4, subtitle: languageData?.modelsCardRole4, workText: languageData?.modelsCardBold4, paidText: languageData?.modelsCardLight4, underSlideTitle: languageData?.modelsCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.modelsCardName1, subtitle: languageData?.modelsCardRole1, workText: languageData?.modelsCardBold1, paidText: languageData?.modelsCardLight1, underSlideTitle: languageData?.modelsCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageData?.modelsCardName2, subtitle: languageData?.modelsCardRole2, workText: languageData?.modelsCardBold2, paidText: languageData?.modelsCardLight2, underSlideTitle: languageData?.modelsCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.modelsCardName3, subtitle: languageData?.modelsCardRole3, workText: languageData?.modelsCardBold3, paidText: languageData?.modelsCardLight3, underSlideTitle: languageData?.modelsCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.modelsCardName4, subtitle: languageData?.modelsCardRole4, workText: languageData?.modelsCardBold4, paidText: languageData?.modelsCardLight4, underSlideTitle: languageData?.modelsCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.modelsCardName1, subtitle: languageData?.modelsCardRole1, workText: languageData?.modelsCardBold1, paidText: languageData?.modelsCardLight1, underSlideTitle: languageData?.modelsCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])

    
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Models - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.modelsQuoteDesc} quoteName={languageData?.modelsQuoteName} quoterole={languageData?.modelsQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Models
