import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RoutesList from "./Pages/RoutesList";
import NotFound from "./Pages/NotFound";
import { LanguageProvider } from "./utills/LanguageContext";

function App() {
  const params = new URLSearchParams(window.location.search);
  const variant = params.get("p");

  return (
    <>
      <LanguageProvider>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<RoutesList />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
      </LanguageProvider>
    </>
  );
}

export default App;
