import React, { useEffect } from 'react'
import Header from '../Components/Header'
import whatSectionMobile from '../Images/hostPage/whatMobile.gif'
import whatSectionDesk from '../Images/hostPage/whatDesk.gif'
import SlidePlan from '../Components/hostPage/SlidePlan'
import tierIcon1 from '../Images/hostPage/tierIcon1.png'
import tierIcon2 from '../Images/hostPage/tierIcon2.png'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useLanguage } from '../utills/LanguageContext'
import FaqSlug from '../Components/FaqSlug'
import TierSlider from '../Components/hostPage/TierSlider'
import loadingIcon from '../Images/hostPage/loading.svg'
import NotFound from './NotFound'
import mixpanel from 'mixpanel-browser'
import { Helmet } from 'react-helmet'

function Host({ setHostData }) {
    const { language } = useLanguage();
    let { slug } = useParams();

    useEffect(() => {
        if (slug === undefined) return
        mixpanel.init("d57c793a5a854ab09bb886794444b5bb")

        mixpanel.track_pageview(
            {
                host: slug,
                url: window.location.href,
            }
        );
    }, []);

    const [notFound, setNotFound] = useState(false);

    const [host, setHost] = useState(null);
    const [data, setData] = useState(null);

    const { isLoading, isPending } = useQuery({
        queryKey: ["hosts"],
        enabled: language !== null && language !== undefined,
        queryFn: async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/hosts/${slug}/profile`);
                setData(res.data)
                processData(res.data);
               
                return res.data;
            } catch (error) {

                console.error(error);
                return setNotFound(true);
            }
        },
    });

    const processData = async (data) => {
        
        console.log(data, language);
        let content = data.sections[language][0];
        setHostData({ slug: data.url_slug, firstName: data.firstName });
        let hostData = {
            content: content,
            firstName: data.firstName,
            lastName: data.lastName,
        }
        setHost(hostData);
    }
    
    useEffect(() => {
        if (data && language) {
            processData(data);
        }
    }, [data, language]);

    const processCTALink = (link) => {
        let utm_params = new URLSearchParams(window.location.search);

        if (link.includes('?')) {
            return link + '&' + utm_params.toString();
        } else {
            return link + '?' + utm_params.toString();
        }        
    }

    if (isLoading || isPending) {
        return (
            <div className="w-screen h-screen flex justify-center items-center fixed bg-purple-600 z-50">
                <img className='w-[50px]' src={loadingIcon} alt="loadingIcon" />
            </div>
        );
    }

    if (notFound) {
        return <NotFound />
    }

    return (
        <section>
            <Helmet>
                <title>Conteúdos exclusivos dos seus creators preferidos na Morgis</title>
                <meta name="description" content="Encontre e conecte-se com os seus criadores de conteúdo preferidos na Morgis." />

            </Helmet>
            <div className='relative h-full pb-8'>
                <Header />

                <div className='w-full mt-[50px] bg-center bg-cover bg-no-repeat  relative'>
                    <img className='absolute w-full heightForSafari md:h-[870px] object-cover' src={host.content.section1.section1_bg} alt="hostHeroMobile" />
                    <div className='px-4 relative z-10 flex flex-col heightForSafari md:h-[870px]'>
                        <div className='flex-grow'>
                            <div className='max-w-[1200px] w-full m-auto'>
                                <h2 className='text-[64px] md:text-[128px] inter text-white font-bold leading-8 pt-10 md:pt-24 mtForMac'>{host.firstName}</h2>
                                <h2 className='text-[64px] md:text-[128px] inter text-white font-bold pl-6 md:pl-12 leading-[50px] md:leading-[unset] my-6 md:my-0'>{host.lastName}</h2>
                                <div className='flex items-center mt-[-5px]'>
                                    <p className='bg-[#8649D1] text-white font-normal px-[1px] text-[32px] md:text-[64px] leading-9 md:leading-[75px] montserrat'>{host.content.section1.section1_tag_1}</p>
                                    <p className='text-white font-normal px-[1px] text-[32px] md:text-[64px] leading-8 montserrat mx-1 md:mx-3'>&</p>
                                    <p className='bg-[#8649D1] text-white font-normal px-[1px] text-[32px] md:text-[64px] leading-9 md:leading-[75px] montserrat'>{host.content.section1.section1_tag_2}</p>
                                </div>
                            </div>
                        </div>
                        <div className='mb-8 md:mb-12'>
                            <div className='max-w-[1200px] xl:max-w-[90%] w-full m-auto absolute left-0 right-0 bottom-14 md:bottom-12'>
                                <p className='text-2xl md:text-5xl text-white font-normal montserrat text-center mtForMac2'>{host.content.section1.section1_top_title}</p>
                                <p className='text-[32px] md:text-[70px] 2xl:text-[80px] text-white font-semibold montserrat text-center flex items-center justify-center pb-[120px] md:pb-32 mbForMac'>{host.content.section1.section1_bottom_title}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-bgContent bg-cover bg-center bg-no-repeat p-4 py-6 md:pb-12'>
                    <p className='text-center text-[32px] md:text-[64px] text-white font-bold max-w-[250px] md:max-w-[unset] m-auto leading-[38px] Inter md:mt-6'> <span className='text-[#FEB80B]'>{host.content.section2?.section_2_colored}</span> {host.content.section2?.section_2_text}</p>

                    <div className='max-w-[1200px] w-full m-auto'>
                        {host.content.section2.content.map((block, index) => (
                            <div className={`${index % 2 === 0 ? '' : 'flex justify-end'}`}>
                                <div
                                    key={index}
                                    className={`max-w-[270px] md:max-w-[unset] w-full md:flex items-center mt-6 md:mt-16 ${index % 2 === 0 ? '' : 'md:flex-row-reverse '} md:mt-12`}
                                >
                                    <img className='hostContentBlockShadow rounded-[16px] max-w-[270px] md:max-w-[480px] w-full mt-3' src={block.image} alt={`content-${index}`} />
                                    <div className={`max-w-[250px] md:max-w-[480px] w-full m-auto md:m-[unset] ${index % 2 === 0 ? 'md:pl-4' : 'md:pr-4'}`}>
                                        <p className={`text-white font-bold inter text-lg md:text-[32px] pr-3 mt-1 leading-5 md:leading-[38px] ${index % 2 === 0 ? '' : 'text-end'}`}>
                                            {block.top_text}
                                        </p>
                                        <p className={`text-white font-normal inter text-sm md:text-[28px] mt-1 md:leading-[32px] md:pt-6 ${index % 2 === 0 ? '' : 'text-end pr-3'}`}>
                                            {block.bottom_text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
                {
                    host.content.section_plans?.enabled === true && <div>
                        <div className=' h-auto bg-cover bg-center flex justify-center items-center relative'>
                            <img className='absolute w-full h-full object-cover' src={host.content.section_plans.section_plans_bg} alt="bgTier1Desk" />
                            <div className='max-w-[870px] w-full px-4 md:px-0 relative z-10 py-12'>
                                <p className='text-[32px] md:text-[56px] inter tracking-[2.8px] text-white'>Start your <span className='bg-[#8649D2]'>membership</span> <span className='bg-[#8649D2]'>now</span>!</p>
                                <div className='flex items-center  mt-4'><img className='w-[34px] md:w-[60px] mr-3' src={tierIcon1} alt="tierIcon1" /><p className='text-[22px] md:text-[38px] text-white'>Have access to my exclusive <span className='text-[#FEB80B] font-bold'> broadcast groups</span></p></div>
                                <div className='flex items-center mt-5'><img className='w-[34px] md:w-[60px] mr-3 ' src={tierIcon2} alt="tierIcon2" /><p className='text-[22px] md:text-[38px] text-white'>Be surprised <span className='text-[#FEB80B] font-bold'> every month!</span></p></div>
                            </div>
                        </div>
                        <div className='bg-bgTier2Desk pt-12 bg-cover bg-center flex justify-center items-center'>
                            <TierSlider data={host.content.section_plans.plans} />
                        </div>
                    </div>

                }
                <div className='relative w-full h-[600px] md:h-[1000px] flex items-end'>
                    <img
                        className='absolute w-full hidden md:block md:h-[1000px] object-cover blur-lg'
                        src={host.content.section3.section_3_bg}
                        alt="bgGetVideo"
                    />
                    <img
                        className='absolute h-[600px] md:h-[1000px] w-full object-cover object-top max-w-[800px] m-auto left-1/2 transform -translate-x-1/2'
                        src={host.content.section3.section_3_bg}
                        alt="bgGetVideo"
                    />
                    <div className='relative z-10 max-w-[330px] md:max-w-[800px] w-full mx-auto pb-5 md:pb-[150px]'>
                        <div className='getVideoLineGradient w-full h-[2px] mb-3 md:mb-8'></div>
                        <p className='text-[22px] md:text-[44px] text-white font-extralight leading-[26px] md:leading-[53px] italic md:max-w-[620px] md:m-auto text-center forBold'
                            dangerouslySetInnerHTML={{ __html: host.content.section3.section_3_text }}>
                        </p>
                        <div className='getVideoLineGradient w-full h-[2px] mt-3 md:mt-8'></div>
                        <Link target='_blank' to={processCTALink(host.content.section3.section_3_cta_link)}>
                            <p className='text-center text-white underline cursor-pointer text-base md:text-[32px] font-medium mt-4 md:mt-8'>
                                {host.content.section3.section_3_cta_text}
                            </p>
                        </Link>
                    </div>
                </div>

                <div className='bg-[#684DCE] w-full pb-20'>
                    <img className='w-full md:hidden' src={whatSectionMobile} alt="whatSectionMobile" />
                    <img className='w-full hidden md:block max-w-[1200px] m-auto' src={whatSectionDesk} alt="whatSectionDesk" />
                    <div className='max-w-[840px] m-auto'>
                        <p className='text-[32px] md:text-[64px] text-center text-white font-bold tracking-[2px] md:mt-[-100px] leading-[1.2] md:leading-[70px]'>{host.content?.section_institutional?.section_institutional_title}</p>
                        <p className='text-sm md:text-[28px] text-center text-white font-medium opacity-75 mt-4 md:leading-8'>{host.content?.section_institutional?.section_institutional_text}</p>
                    </div>

                </div>
                <div className='bg-bgSliderHost bg-center bg-cover py-12'>
                    <SlidePlan host={host} />
                </div>
                <div className='pb-6'>
                    <div className='py-4 md:flex md:justify-center items-center md:my-4'>
                        <p className='text-[32px] md:text-[48px] inter text-center font-normal text-[#9A85F5] leading-7 md:tracking-[2.4px] md:pr-2'>{host.content.section6.section_6_title_left} </p>
                        <p className='text-[32px] md:text-[48px] inter text-center font-extrabold text-[#9A85F5] bg-[#FEB80B] px-2 m-auto md:m-[unset] tracking-[1.6px] leading-10 md:py-4'>{host.content.section6.section_6_title_right}</p>
                    </div>
                    <img className='w-[90%] max-w-[600px] m-auto' src={host.content.section6.section_6_gif} alt="Gif" />
                </div>
                <Link onClick={() => {
                    mixpanel.track("cta_click", {
                        host: slug,
                        url: window.location.href,
                    });
                }} className=' flex justify-center items-center w-full m-auto sticky bottom-6 md:bottom-10 z-[49] max-w-[300px] md:max-w-[400px]' target='_blank' 
                to={processCTALink(host.content.section1.section1_cta_link)}>
                    <button className='text-white shadow text-xl md:text-[24px] font-bold px-4 md:px-8 m-auto bg-[#8649D1] py-2 md:py-5 rounded-[40px] hostHomeBtnShadow md:tracking-[-0.3px] md:leading-10'>{host.content.section1.section1_cta_text}</button>
                </Link>

            </div>
            <FaqSlug />


        </section>
    )
}

export default Host
