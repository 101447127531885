import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Artists/slide1.gif'
import slide2 from '../Images/Paid/Artists/slide2.gif'
import slide3 from '../Images/Paid/Artists/slide3.gif'
import slide4 from '../Images/Paid/Artists/slide4.gif'
import slideBg1 from '../Images/bg/Artists/bg1.png'
import slideBg2 from '../Images/bg/Artists/bg2.png'
import slideBg3 from '../Images/bg/Artists/bg3.png'
import slideBg4 from '../Images/bg/Artists/bg4.png'
import slideBgDesk1 from '../Images/bg/Artists/bg1Desk.png'
import slideBgDesk2 from '../Images/bg/Artists/bg2Desk.png'
import slideBgDesk3 from '../Images/bg/Artists/bg3Desk.png'
import slideBgDesk4 from '../Images/bg/Artists/bg4Desk.png'
import { useLanguage } from '../utills/LanguageContext'
import sliderBg1 from '../Images/slideBg/artists1.png'
import sliderBg1Desk from '../Images/slideBg/artists1Desk.png'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

function Artists() {
    
    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.artistsCardName1, subtitle: languageData?.artistsCardRole1, workText: languageData?.artistsCardBold1, paidText: languageData?.artistsCardLight1, underSlideTitle: languageData?.artistsCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.artistsCardName4, subtitle: languageData?.artistsCardRole4, workText: languageData?.artistsCardBold4, paidText: languageData?.artistsCardLight2, underSlideTitle: languageData?.artistsCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.artistsCardName3, subtitle: languageData?.artistsCardRole3, workText: languageData?.artistsCardBold3, paidText: languageData?.artistsCardLight3, underSlideTitle: languageData?.artistsCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.artistsCardName2, subtitle: languageData?.artistsCardRole2, workText: languageData?.artistsCardBold2, paidText: languageData?.artistsCardLight4, underSlideTitle: languageData?.artistsCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageData?.artistsCardName1, subtitle: languageData?.artistsCardRole1, workText: languageData?.artistsCardBold1, paidText: languageData?.artistsCardLight1, underSlideTitle: languageData?.artistsCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.artistsCardName4, subtitle: languageData?.artistsCardRole4, workText: languageData?.artistsCardBold4, paidText: languageData?.artistsCardLight2, underSlideTitle: languageData?.artistsCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.artistsCardName3, subtitle: languageData?.artistsCardRole3, workText: languageData?.artistsCardBold3, paidText: languageData?.artistsCardLight3, underSlideTitle: languageData?.artistsCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.artistsCardName2, subtitle: languageData?.artistsCardRole2, workText: languageData?.artistsCardBold2, paidText: languageData?.artistsCardLight4, underSlideTitle: languageData?.artistsCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        
    ];

    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])
    
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Artists - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.artistsQuoteDesc} quoteName={languageData?.artistsQuoteName} quoterole={languageData?.artistsQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Artists
