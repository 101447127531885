

import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Licensed/slide1.gif'
import slide2 from '../Images/Paid/Licensed/slide2.gif'
import slide3 from '../Images/Paid/Licensed/slide3.gif'
import slide4 from '../Images/Paid/Licensed/slide4.gif'
import slideBg1 from '../Images/bg/Licensed/bg1.png'
import slideBg2 from '../Images/bg/Licensed/bg2.png'
import slideBg3 from '../Images/bg/Licensed/bg3.png'
import slideBg4 from '../Images/bg/Licensed/bg4.png'
import slideBgDesk1 from '../Images/bg/Licensed/bg1Desk.png'
import slideBgDesk2 from '../Images/bg/Licensed/bg2Desk.png'
import slideBgDesk3 from '../Images/bg/Licensed/bg3Desk.png'
import slideBgDesk4 from '../Images/bg/Licensed/bg4Desk.png'
import { useLanguage } from '../utills/LanguageContext'
import sliderBg1 from '../Images/slideBg/influencer1.png'
import sliderBg1Desk from '../Images/slideBg/influencer1Desk.png'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

function Licensed() {
    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.licensedCardName1, subtitle: languageData?.licensedCardRole1, workText: languageData?.licensedCardBold1, workText2: languageData?.licensedCardBold1_db, paidText: languageData?.licensedCardLight1, underSlideTitle: languageData?.licensedCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.licensedCardName2, subtitle: languageData?.licensedCardRole2, workText: languageData?.licensedCardBold2, paidText: languageData?.licensedCardLight2, underSlideTitle: languageData?.licensedCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.licensedCardName3, subtitle: languageData?.licensedCardRole3, workText: languageData?.licensedCardBold3, workText2: languageData?.licensedCardBold3_db, paidText: languageData?.licensedCardLight3, underSlideTitle: languageData?.licensedCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.licensedCardName4, subtitle: languageData?.licensedCardRole4, workText: languageData?.licensedCardBold4, paidText: languageData?.licensedCardLight4, underSlideTitle: languageData?.licensedCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageData?.licensedCardName1, subtitle: languageData?.licensedCardRole1, workText: languageData?.licensedCardBold1, workText2: languageData?.licensedCardBold1_db, paidText: languageData?.licensedCardLight1, underSlideTitle: languageData?.licensedCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.licensedCardName2, subtitle: languageData?.licensedCardRole2, workText: languageData?.licensedCardBold2, paidText: languageData?.licensedCardLight2, underSlideTitle: languageData?.licensedCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.licensedCardName3, subtitle: languageData?.licensedCardRole3, workText: languageData?.licensedCardBold3, workText2: languageData?.licensedCardBold3_db, paidText: languageData?.licensedCardLight3, underSlideTitle: languageData?.licensedCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.licensedCardName4, subtitle: languageData?.licensedCardRole4, workText: languageData?.licensedCardBold4, paidText: languageData?.licensedCardLight4, underSlideTitle: languageData?.licensedCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])
   
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Licensed Professionals - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.licensedQuoteDesc} quoteName={languageData?.licensedQuoteName} quoterole={languageData?.licensedQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Licensed
