import React from 'react';
import logo from '../../Images/Ingredient/logo.svg';
import { useLanguage } from '../../utills/LanguageContext';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Ingredient({scrollToHubspot}) {
    
    const location = useLocation();
    const { languageData, changeLanguage } = useLanguage();

    const isAgentsFashion = location.pathname.includes("/agents-fashion");
    return (
        <div className='h-[720px] md:h-[1000px] py-4 bg-[#CEA7FF]'>
            <div className={`${isAgentsFashion ? 'bg-ingredientBgAgents md:bg-ingredientBgDeskAgents' : 'bg-ingredientBg md:bg-ingredientBgDesk'} bg-no-repeat bg-[length:90%]  bg-center h-full flex items-center justify-center`}>
                <div className='bg-[#1E1E1ECC] w-[80%] md:max-w-[540px]  md:w-full md:m-auto rounded-[40px] p-10'>
                    <img className='w-[100px] md:w-[170px] m-auto md:my-10' src={logo} alt="logo" />
                    <p className='text-center text-2xl md:text-[37px] font-normal tracking-[-0.5px] mt-5 text-white md:pb-4  w-full m-auto md:leading-10'>{isAgentsFashion ? (<>{languageData?.mainIngredientBlockTitleAgents} <br /> {languageData?.mainIngredientBlockTitleSpanAgents}</>) : (<>{languageData?.mainIngredientBlockTitle} <br /> {languageData?.mainIngredientBlockTitleSpan}</>)}</p>
                    <Link className='w-full' to='https://app.morgis.com/auth/signup-host' target='_blank'>
                        <button onClick={(e) => {
              if (isAgentsFashion === true) {
                e.preventDefault();
                scrollToHubspot();
              }
            }} className='bg-[#8649D1] w-full md:max-w-[380px] m-auto text-white text-base md:text-2xl h-[50px] md:h-[70px] flex items-center justify-center rounded-[40px] mt-3 tracking-[1px] font-bold'>{languageData?.mainIngredientBlockBtn}</button>
                    </Link>
                    <Link className='w-full' to='https://app.morgis.com/auth/login-host' target='_blank'>
                        <p className='text-sm md:text-lg font-normal tracking-[-0.4px] text-center mt-2 text-white'>{languageData?.mainIngredientBlockLink}
                            <span className='text-white border-b border-white pb-[1px] cursor-pointer font-medium ml-2'>{languageData?.mainIngredientBlockLinkSpan}</span>
                        </p>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default Ingredient
