import React from 'react'
import { Link } from 'react-router-dom'
import { useLanguage } from '../../utills/LanguageContext'
import advantagesHeroMob from '../../Images/advantagesHeroMob.png'
import { useLocation } from 'react-router-dom'

function AdvantagesList({scrollToHubspot}) {

    const { languageData } = useLanguage();
    const location = useLocation();

    const isAgentsFashion = location.pathname.includes("/agents-fashion");
    return (
        <div className='h-full gradientAdvantages'>
            <div className='max-w-[730px] w-full m-auto'>
                <img className='w-full max-w-[230px] md:max-w-[590px] m-auto py-4 md:py-12' src={advantagesHeroMob} alt="advantagesHeroMob" />
                <div className='mt-4'>
                    <div className='px-4'>
                        <h2 className='text-[#161616] text-center text-[32px] md:text-[40px] tracking-[5px] font-bold leading-[38px]'>{languageData?.advantagesTitle}</h2>
                        <div className='max-w-[455px] w-full md:pt-5'>
                            <p className='text-2xl md:text-[32px] text-[#532998CC] font-bold mt-4'>{isAgentsFashion ? (languageData?.advantagesLeftTitleAgents) : (languageData?.advantagesLeftTitle)}</p>
                            <p className='text-base md:text-[20px] text-[#705C9099] font-normal mt-2'>{isAgentsFashion ? (languageData?.advantagesLeftSubTitleAgents) : (languageData?.advantagesLeftSubTitle)}</p>
                        </div>
                    </div>
                    <div className='px-4 mt-7 flex justify-end'>
                        <div className='max-w-[455px] w-full'>
                            <p className='text-2xl md:text-[32px] text-[#532998CC] font-bold  text-right'>{isAgentsFashion ? (languageData?.advantagesRightTitleAgents) : (languageData?.advantagesRightTitle)}</p>
                            <p className='text-base md:text-[20px] text-[#705C9099] font-normal text-right mt-2'>{isAgentsFashion ? (languageData?.advantagesRightSubTitleAgents) : (languageData?.advantagesRightSubTitle)}</p>
                        </div>
                    </div>
                </div>
                <div onClick={(e) => {
              if (isAgentsFashion === true) {
                e.preventDefault();
                scrollToHubspot();
              }
            }} className='flex justify-center pb-14 mt-8'>
                    <Link className='w-full' to='https://app.morgis.com/auth/signup-host' target='_blank'>
                        <button className='bg-[#8649D1] w-full max-w-[250px] md:max-w-[450px] m-auto text-white text-lg md:text-2xl h-[50px] md:h-[70px] flex items-center justify-center rounded-[21px] md:rounded-[30px] font-medium my-3'>{languageData?.advantagesHomeBtn}</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AdvantagesList
