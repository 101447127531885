

import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Athlete/slide1.gif'
import slide2 from '../Images/Paid/Athlete/slide3.gif'
import slide3 from '../Images/Paid/Athlete/slide2.gif'
import slide4 from '../Images/Paid/Athlete/slide4.gif'
import slideBg1 from '../Images/bg/Athlete/bg1.png'
import slideBg2 from '../Images/bg/Athlete/bg2.png'
import slideBg3 from '../Images/bg/Athlete/bg3.png'
import slideBg4 from '../Images/bg/Athlete/bg4.png'
import slideBgDesk1 from '../Images/bg/Athlete/bg1Desk.png'
import slideBgDesk2 from '../Images/bg/Athlete/bg2Desk.png'
import slideBgDesk3 from '../Images/bg/Athlete/bg3Desk.png'
import slideBgDesk4 from '../Images/bg/Athlete/bg4Desk.png'
import { useLanguage } from '../utills/LanguageContext'
import sliderBg1 from '../Images/slideBg/athlete1.png'
import sliderBg1Desk from '../Images/slideBg/athlete1Desk.png'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

function Athlete() {
    
    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();


    const slides = [
        { id: 3, img: slide4, title: languageData?.athleteCardName4, subtitle: languageData?.athleteCardRole4, workText: languageData?.athleteCardBold4, paidText: languageData?.athleteCardLight4, underSlideTitle: languageData?.athleteCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 1, img: slide3, title: languageData?.athleteCardName1, subtitle: languageData?.athleteCardRole1, workText: languageData?.athleteCardBold1, paidText: languageData?.athleteCardLight1, underSlideTitle: languageData?.athleteCardSpan1, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide2, title: languageData?.athleteCardName2, subtitle: languageData?.athleteCardRole2, workText: languageData?.athleteCardBold2, paidText: languageData?.athleteCardLight2, underSlideTitle: languageData?.athleteCardSpan2, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 0, img: slide1, title: languageData?.athleteCardName3, subtitle: languageData?.athleteCardRole3, workText: languageData?.athleteCardBold3, paidText: languageData?.athleteCardLight3, underSlideTitle: languageData?.athleteCardSpan3, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 3, img: slide4, title: languageData?.athleteCardName4, subtitle: languageData?.athleteCardRole4, workText: languageData?.athleteCardBold4, paidText: languageData?.athleteCardLight4, underSlideTitle: languageData?.athleteCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 1, img: slide3, title: languageData?.athleteCardName1, subtitle: languageData?.athleteCardRole1, workText: languageData?.athleteCardBold1, paidText: languageData?.athleteCardLight1, underSlideTitle: languageData?.athleteCardSpan1, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide2, title: languageData?.athleteCardName2, subtitle: languageData?.athleteCardRole2, workText: languageData?.athleteCardBold2, paidText: languageData?.athleteCardLight2, underSlideTitle: languageData?.athleteCardSpan2, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 0, img: slide1, title: languageData?.athleteCardName3, subtitle: languageData?.athleteCardRole3, workText: languageData?.athleteCardBold3, paidText: languageData?.athleteCardLight3, underSlideTitle: languageData?.athleteCardSpan3, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },

    ];

    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])

    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Athlete - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.athleteQuoteDesc} quoteName={languageData?.athleteQuoteName} quoterole={languageData?.athleteQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Athlete
