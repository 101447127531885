import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import { EffectCoverflow } from 'swiper/modules';

function SlidePlan({ host }) {
    const [activeSlide, setActiveSlide] = useState(0);


    const duplicatedSlides = [...host.content.section5.slides, ...host.content.section5.slides];

    const handleSlideChange = (swiper) => {
        setActiveSlide(swiper.realIndex % host.content.section5.slides.length); 
    };

    return (
        <>
            <div className="max-w-[500px] md:max-w-[800px] m-auto w-full">
                <Swiper
                    effect="coverflow"
                    loop={true}
                    grabCursor={true}
                    centeredSlides={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.5,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                    }}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 100,
                        depth: 300,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={{ clickable: true }}
                    onSlideChange={handleSlideChange}
                    modules={[EffectCoverflow]}
                    className="mySwiper"
                >
                    {duplicatedSlides.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={slide.image}
                                alt={`Slide ${index + 1}`}
                                className="w-full object-cover rounded-2xl"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="h-[150px]">
                <p className="text-[32px] md:text-[64px] font-semibold text-white inter text-center leading-[38px] mt-8 px-2">
                    {host.content.section5.slides[activeSlide]?.title}
                </p>
                <p className="text-base md:text-[32px] text-center text-white font-medium leading-5 md:leading-[40px] tracking-[0.8px] px-2 mt-4 md:mt-10 md:max-w-[800px] md:m-auto">
                    {host.content.section5.slides[activeSlide]?.description}
                </p>
            </div>
        </>
    );
}

export default SlidePlan;
