import React from 'react'
import heroDesk from '../Images/hostPage/notFoundDesk.png';
import heroMob from '../Images/hostPage/notFoundMob.png';

function NotFound() {
    return (
        <div className='flex items-center justify-center h-screen'>
            <div className='relative'>
                <img className='max-w-[1100px] w-full m-auto px-2 hidden lg:block' src={heroDesk} alt="heroDesk" />
                <img className='max-w-[340px] w-full m-auto px-2 lg:hidden' src={heroMob} alt="heroMob" />
                <p className='text-[24px] lg:text-[64px] text-white font-medium absolute bottom-[25px] right-[40px] lg:bottom-[100px] lg:right-[100px]'>Ops, page not found</p>
            </div>

        </div>
    )
}

export default NotFound
