
import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Teachers/slide1.gif';
import slide2 from '../Images/Paid/Teachers/slide2.gif';
import slide3 from '../Images/Paid/Teachers/slide3.gif';
import slide4 from '../Images/Paid/Teachers/slide4.gif';
import slideBg1 from '../Images/bg/Teachers/bg1.png';
import slideBg2 from '../Images/bg/Teachers/bg2.png';
import slideBg3 from '../Images/bg/Teachers/bg3.png';
import slideBg4 from '../Images/bg/Teachers/bg4.png';
import slideBgDesk1 from '../Images/bg/Teachers/bg1Desk.png';
import slideBgDesk2 from '../Images/bg/Teachers/bg2Desk.png';
import slideBgDesk3 from '../Images/bg/Teachers/bg3Desk.png';
import slideBgDesk4 from '../Images/bg/Teachers/bg4Desk.png';
import { useLanguage } from '../utills/LanguageContext';
import sliderBg1 from '../Images/slideBg/teachers1.png';
import sliderBg1Desk from '../Images/slideBg/teachers1Desk.png';
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

function Teachers() {

    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.teachersCardName2, subtitle: languageData?.teachersCardRole2, workText: languageData?.teachersCardBold2, workText2: languageData?.teachersCardBold2_db, paidText: languageData?.teachersCardLight2, underSlideTitle: languageData?.teachersCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.teachersCardName3, subtitle: languageData?.teachersCardRole3, workText: languageData?.teachersCardBold3, workText2: languageData?.teachersCardBold3_db, paidText: languageData?.teachersCardLight3, underSlideTitle: languageData?.teachersCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.teachersCardName4, subtitle: languageData?.teachersCardRole4, workText: languageData?.teachersCardBold4, workText2: languageData?.teachersCardBold4_db, paidText: languageData?.teachersCardLight4, underSlideTitle: languageData?.teachersCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.teachersCardName1, subtitle: languageData?.teachersCardRole1, workText: languageData?.teachersCardBold1, workText2: languageData?.teachersCardBold1_db, paidText: languageData?.teachersCardLight1, underSlideTitle: languageData?.teachersCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageData?.teachersCardName2, subtitle: languageData?.teachersCardRole2, workText: languageData?.teachersCardBold2, workText2: languageData?.teachersCardBold2_db, paidText: languageData?.teachersCardLight2, underSlideTitle: languageData?.teachersCardSpan2, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.teachersCardName3, subtitle: languageData?.teachersCardRole3, workText: languageData?.teachersCardBold3, workText2: languageData?.teachersCardBold3_db, paidText: languageData?.teachersCardLight3, underSlideTitle: languageData?.teachersCardSpan3, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.teachersCardName4, subtitle: languageData?.teachersCardRole4, workText: languageData?.teachersCardBold4, workText2: languageData?.teachersCardBold4_db, paidText: languageData?.teachersCardLight4, underSlideTitle: languageData?.teachersCardSpan4, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.teachersCardName1, subtitle: languageData?.teachersCardRole1, workText: languageData?.teachersCardBold1, workText2: languageData?.teachersCardBold1_db, paidText: languageData?.teachersCardLight1, underSlideTitle: languageData?.teachersCardSpan1, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])

    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Teachers - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.teachersQuoteDesc} quoteName={languageData?.teachersQuoteName} quoterole={languageData?.teachersQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Teachers
