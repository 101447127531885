import React, { useEffect } from 'react';
import { useLanguage } from '../utills/LanguageContext';

export default function HostRedirect() {

    const { changeLanguage } = useLanguage();
    const params = new URLSearchParams(window.location.search);
    const paramsString = params.toString();

    useEffect(() => {

        const url = window.location.href;
        const slug = url.split('/')[4] || '';
        const lang = url.split('/')[3] || '';
        console.log('lang', lang)

        if (lang === 'en' || lang === 'es' || lang === 'pt') {
            changeLanguage(lang);
        }
       window.location.href = `/${slug}` + (paramsString && window.location.href.indexOf('?') === -1 ? `?${paramsString}` : '');

    }, [])

    return (
        <div>HostRedirect</div>
    )
}
