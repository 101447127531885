import React, { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../../utills/LanguageContext';
import { Link } from 'react-router-dom';
import videoPlay3_1 from '../../Images/video3.gif';
import videoPlay3_2 from '../../Images/video4.gif';
import videoPlay4 from '../../Images/video1.gif';
import videoPlay5 from '../../Images/video2.gif';
import { useLocation } from "react-router-dom";

function Host({scrollToHubspot}) {
    const [activeTab, setActiveTab] = useState(0);
    const [isPlaying, setIsPlaying] = useState([false, false, false, false]);
    const [currentGifIndex, setCurrentGifIndex] = useState(0); // New state for toggling gifs
    const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const { languageData } = useLanguage();

   
    const tabsDefault  = [
        { id: 0, label: languageData?.hostTabs1Title, content: languageData?.hostTabs1Subtitle, gif: videoPlay4 },
        { id: 1, label: languageData?.hostTabs2Title, content: languageData?.hostTabs2Subtitle, gif: videoPlay3_1 },
        { id: 2, label: languageData?.hostTabs3Title, content: languageData?.hostTabs3Subtitle, gif:  videoPlay3_2 },
        { id: 3, label: languageData?.hostTabs4Title, content: languageData?.hostTabs4Subtitle, gif: videoPlay5 }
    ];

    const tabsAgents = [
        { id: 0, label: languageData?.hostTabs1TitleAgents, content: languageData?.hostTabs1SubtitleAgents, gif: videoPlay4 },
        { id: 1, label: languageData?.hostTabs2TitleAgents, content: languageData?.hostTabs2SubtitleAgents, gif: videoPlay3_1 },
        { id: 2, label: languageData?.hostTabs3TitleAgents, content: languageData?.hostTabs3SubtitleAgents, gif:  videoPlay3_2},
        { id: 3, label: languageData?.hostTabs4TitleAgents, content: languageData?.hostTabs4SubtitleAgents, gif: videoPlay5 }
    ];
    
    const location = useLocation();

    const isAgentsFashion = location.pathname.includes("/agents-fashion");
  
    const tabs = isAgentsFashion ? tabsAgents : tabsDefault;

    useEffect(() => {
        let gifTimer;
        if (activeTab === 2) {
            // Set up a timer to toggle between gifs for tab id: 2
            gifTimer = setInterval(() => {
                setCurrentGifIndex((prevIndex) => (prevIndex === 0 ? 1 : 0)); // Toggle between 0 and 1
            }, 3500); // Change gif every 3 seconds
        }

        return () => {
            clearInterval(gifTimer); // Clear timer on component unmount or when activeTab changes
        };
    }, [activeTab]); // Dependency array with activeTab ensures effect runs when activeTab changes

    const handleTabClick = (index) => {
        // Pause the current playing video if any
        videoRefs[activeTab]?.current?.pause();

        // Update active tab and reset isPlaying state
        setActiveTab(index);
        setIsPlaying([false, false, false, false]);
    };

    const handlePlayVideo = (index) => {
        setIsPlaying(prevState => {
            const updatedPlayingState = prevState.map((playing, i) => (i === index ? true : false));
            return updatedPlayingState;
        });
    };

    return (
        <div className='hostBg p-4 md:p-6'>
            <div className='max-w-[600px] md:max-w-[740px] m-auto'>
                <p className='text-[40px] md:text-[72px] md:text-center font-normal text-[#00063D] leading-8 mt-10'>{languageData?.hostNewTitle}</p>
                <p className='text-[40px] md:text-[72px] md:text-center font-bold text-[#00063D]'>{languageData?.hostNewSubTitle}</p>
                <div className="flex bg-[#8649D1] rounded-2xl mt-4">
                    <div className="w-full bg-purple-600 rounded-l-2xl">
                        {tabs.map((tab, index) => (
                            <div
                                key={tab.id}
                                className={`block w-full text-left text-lg p-4 text-white font-bold relative ${activeTab === index ? 'bg-white rounded-l-2xl text-[#8649D1] active-tab' : 'bg-transparent'
                                    } ${index === tabs.length - 1 ? 'active-tab-last' : ''} ${index === 0 ? 'active-tab-first' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                <p className={`font-bold text-center text-[14px] md:text-[32px] md:py-[29px] cursor-pointer leading-5 md:leading-8 mb-2 ${activeTab === index ? 'text-[#8649D1]' : 'text-white'
                                    }`}>{tab.label}</p>
                                <p className={`text-[11px] md:text-[18px] font-light text-[#8649D1] text-center leading-3 md:leading-5 ${activeTab === index ? 'mb-5' : ''}`}>{activeTab === index && tab.content}</p>
                            </div>
                        ))}
                    </div>
                    <div className="w-full bg-white rounded-2xl rounded-tl-none p-4">
                        <div className='relative'>
                            <img className='w-full h-[280px] md:h-[500px] object-cover rounded-lg' src={tabs[activeTab].gif} alt="gif" />
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mt-6'>
                    <Link onClick={(e) => {
              if (isAgentsFashion === true) {
                e.preventDefault();
                scrollToHubspot();
              }
            }} className='w-full' to='https://app.morgis.com/auth/signup-host' target='_blank'>
                        <button className='bg-[#8649D1] w-full max-w-[250px] md:max-w-[450px] m-auto text-white text-lg md:text-2xl h-[50px] md:h-[70px] flex items-center justify-center rounded-[21px] md:rounded-[30px] font-medium my-3'>{languageData?.mainHomeBtn}</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Host;
