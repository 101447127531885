"use client";
import React, { createContext, useState, useContext, useEffect } from 'react';
import API from './API.js';
import langs from '../morgis-lokaliser.json';

const LanguageContext = createContext({
    language: null,
    changeLanguage: (newLanguage) => { },
    languageData: {}
});

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en'); // Початкове значення
    const [languageData, setLanguageData] = useState({});

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (!storedLanguage) {
            API.getUserCountry()
                .then((result) => {
                    let userLang;
                    switch (result) {
                        case 'EN':
                        case 'US':
                            userLang = 'en';
                            setLanguageData(langs.en);
                            break;
                        case 'es':
                        case 'ES':
                            userLang = 'es';
                            setLanguageData(langs.es);
                        case 'BR':
                        case 'PT':
                            userLang = 'pt';
                            setLanguageData(langs.pt);
                            break;
                        default:
                            userLang = 'en';
                            setLanguageData(langs.en);
                            break;
                    }
                    setLanguage(userLang);
                    localStorage.setItem('language', userLang);
                })
                .catch((error) => {
                    console.error("Error fetching user country:", error);
                });
        } else {
            setLanguage(storedLanguage);
            setLanguageData(
                storedLanguage === 'pt'
                    ? langs.pt || langs.pt
                    : storedLanguage === 'es'
                        ? langs.es || langs.es
                        : langs.en || langs.en
            );
        }
    }, [language]);

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        setLanguageData(
            newLanguage === 'pt'
                ? langs.pt
                : newLanguage === 'es'
                    ? langs.es
                    : langs.en
        );
        localStorage.setItem('language', newLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, languageData }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};