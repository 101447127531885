import React, { useState } from 'react'
import Create from './Main/Create'
import Faq from '../Components/Faq'
import Home from './Main/Home'
import Host from './Main/Host'
import Ingredient from './Main/Ingredient'
import SliderBg from './Main/SliderBg'
import AdvantagesList from './Main/AdvantagesList'
import slide1 from '../Images/Paid/Entrepreneurs/slide1.gif'
import slide2 from '../Images/Paid/Entrepreneurs/slide2.gif'
import slide3 from '../Images/Paid/Entrepreneurs/slide3.gif'
import slide4 from '../Images/Paid/Entrepreneurs/slide4.gif'
import slideBg1 from '../Images/bg/Entrepreneurs/bg1.png'
import slideBg2 from '../Images/bg/Entrepreneurs/bg2.png'
import slideBg3 from '../Images/bg/Entrepreneurs/bg3.png'
import slideBg4 from '../Images/bg/Entrepreneurs/bg4.png'
import slideBgDesk1 from '../Images/bg/Entrepreneurs/bg1Desk.png'
import slideBgDesk2 from '../Images/bg/Entrepreneurs/bg2Desk.png'
import slideBgDesk3 from '../Images/bg/Entrepreneurs/bg3Desk.png'
import slideBgDesk4 from '../Images/bg/Entrepreneurs/bg4Desk.png'
import { useLanguage } from '../utills/LanguageContext'
import sliderBg1 from '../Images/slideBg/entrepreneurs1.png'
import sliderBg1Desk from '../Images/slideBg/entrepreneurs1Desk.png'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

function Entrepreneurs() {
    
    const [activeSlide, setActiveSlide] = useState(0);
    const { languageData, changeLanguage } = useLanguage();

    const slides = [
        { id: 0, img: slide1, title: languageData?.entrepreneursCardName1, subtitle: languageData?.entrepreneursCardRole1, workText: languageData?.entrepreneursCardBold1, paidText: languageData?.entrepreneursCardLight1, underSlideTitle: languageData?.entrepreneursCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.entrepreneursCardName2, subtitle: languageData?.entrepreneursCardRole2, workText: languageData?.entrepreneursCardBold2, paidText: languageData?.entrepreneursCardLight2, underSlideTitle: languageData?.entrepreneursCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.entrepreneursCardName3, subtitle: languageData?.entrepreneursCardRole3, workText: languageData?.entrepreneursCardBold3, paidText: languageData?.entrepreneursCardLight3, underSlideTitle: languageData?.entrepreneursCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.entrepreneursCardName4, subtitle: languageData?.entrepreneursCardRole4, workText: languageData?.entrepreneursCardBold4, paidText: languageData?.entrepreneursCardLight4, underSlideTitle: languageData?.entrepreneursCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
        { id: 0, img: slide1, title: languageData?.entrepreneursCardName1, subtitle: languageData?.entrepreneursCardRole1, workText: languageData?.entrepreneursCardBold1, paidText: languageData?.entrepreneursCardLight1, underSlideTitle: languageData?.entrepreneursCardSpan1, imageBg: slideBg1, imageBgDesk: slideBgDesk1 },
        { id: 1, img: slide2, title: languageData?.entrepreneursCardName2, subtitle: languageData?.entrepreneursCardRole2, workText: languageData?.entrepreneursCardBold2, paidText: languageData?.entrepreneursCardLight2, underSlideTitle: languageData?.entrepreneursCardSpan2, imageBg: slideBg2, imageBgDesk: slideBgDesk2 },
        { id: 2, img: slide3, title: languageData?.entrepreneursCardName3, subtitle: languageData?.entrepreneursCardRole3, workText: languageData?.entrepreneursCardBold3, paidText: languageData?.entrepreneursCardLight3, underSlideTitle: languageData?.entrepreneursCardSpan3, imageBg: slideBg3, imageBgDesk: slideBgDesk3 },
        { id: 3, img: slide4, title: languageData?.entrepreneursCardName4, subtitle: languageData?.entrepreneursCardRole4, workText: languageData?.entrepreneursCardBold4, paidText: languageData?.entrepreneursCardLight4, underSlideTitle: languageData?.entrepreneursCardSpan4, imageBg: slideBg4, imageBgDesk: slideBgDesk4 },
    ];

    useEffect(() => {
        const lang = window.location.pathname.split('/')[1];
        changeLanguage(lang);
    }, [])
    
    const sliderBg = [
        {
            imageUrl: sliderBg1,
            imageUrlDesk: sliderBg1Desk
        }
    ];
    return (
        <div>
            <Helmet>
                <title>Entrepreneurs - Creators Morgis</title>
            </Helmet>
            <div className='mt-[50px]'>
                <Home slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
                <Create />
                <Host />
                <AdvantagesList />
                <SliderBg sliderBg={sliderBg} quoteTitle={languageData?.entrepreneursQuoteDesc} quoteName={languageData?.entrepreneursQuoteName} quoterole={languageData?.entrepreneursQuoteRole} />
                <Ingredient />
                <Faq />
            </div>
        </div>
    )
}

export default Entrepreneurs
